<template>
    <app-layout>
        <loader v-if="loading" absolute />

        <div v-else class="docs">
            <card>
                <template #icon>
                    <console-icon />
                </template>

                <template #title>SkinPay API</template>

                <scroll-container>
                    <ul class="docs__list">
                        <li
                            v-for="(item, index) in endpoints"
                            :key="index"
                            :class="{ 'docs__list-item': true, 'docs__list-item_selected': index === selected }"
                            @click="selected = index"
                        >
                            {{ item.title }}
                        </li>
                    </ul>
                </scroll-container>
            </card>

            <card>
                <template #title>{{ selectedEndpoint.title }}</template>

                <p class="docs__url">
                    <span>{{ selectedEndpoint.method.toUpperCase() }}:</span>
                    {{ selectedEndpoint.url }}
                </p>

                <div v-if="selectedEndpoint.description" class="docs__description" v-html="selectedEndpoint.description" />

                <table-component v-if="hasParameters" class="docs__parameters">
                    <template #head>
                        <tr>
                            <th>{{ $t('docs.table.parameter') }}</th>
                            <th>{{ $t('docs.table.type') }}</th>
                            <th>{{ $t('docs.table.description') }}</th>
                        </tr>
                    </template>

                    <template #body>
                        <tr v-for="(parameter, index) in selectedEndpoint.parameters" :key="index">
                            <table-cell :theme="parameter.required ? 'primary' : null">
                                <template #heading>{{ $t('docs.table.parameter') }}</template>

                                <template #data>{{ parameter.name }}</template>
                            </table-cell>

                            <table-cell>
                                <template #heading>{{ $t('docs.table.type') }}</template>

                                <template #data>{{ parameter.type }}</template>
                            </table-cell>

                            <table-cell>
                                <template #heading>{{ $t('docs.table.description') }}</template>

                                <template #data>{{ parameter.description }}</template>
                            </table-cell>
                        </tr>
                    </template>
                </table-component>

                <div v-if="selectedEndpoint.responses.length" v-for="(response, index) in endpointResponses" :key="index" class="docs__response">
                    <h1>{{ $t('docs.response_with_status') }} {{ response.status }}</h1>

                    <code-snippet language="json" :code="response.content" />
                </div>
            </card>
        </div>
    </app-layout>
</template>

<script>
    import _ from 'underscore';
    import { sample } from 'openapi-sampler';
    import SwaggerParser from '@apidevtools/swagger-parser';
    import { apiDocs } from '@/services/skinsPaymentApi';
    import AppLayout from '@/pages/layout/AppLayout';
    import Card from '@/components/Card';
    import CodeSnippet from '@/components/CodeSnippet';
    import ConsoleIcon from '@/components/icons/ConsoleIcon';
    import Loader from '@/components/Loader';
    import ScrollContainer from '@/components/ScrollContainer';
    import TableCell from '@/components/tables/TableCell';
    import TableComponent from '@/components/tables/TableComponent';

    export default {
        name: 'Docs',
        components: {
            AppLayout,
            Card,
            CodeSnippet,
            ConsoleIcon,
            Loader,
            ScrollContainer,
            TableCell,
            TableComponent,
        },
        computed: {
            endpointResponses() {
                return _.filter(this.selectedEndpoint.responses, response => !!response.content);
            },
            hasParameters() {
                return this.selectedEndpoint.parameters && this.selectedEndpoint.parameters.length;
            },
            selectedEndpoint() {
                return this.endpoints[this.selected];
            },
        },
        methods: {
            fetchApiDocs() {
                this.loading = true;

                apiDocs(this.$i18n.locale)
                    .then(async ({ data }) => {
                        this.globalData = await SwaggerParser.dereference(data);
                        this.endpoints = this.formatEndpoints(data.paths);
                    })
                    .catch(() => this.$toast.error(this.$t('docs.error')))
                    .finally(() => (this.loading = false));
            },
            formatEndpoints(endpoints) {
                return _.flatten(
                    _.map(endpoints, (endpoint, url) =>
                        _.map(endpoint, (method, name) => {
                            url = url.replace(/\/+$/, '');

                            return {
                                method: name,
                                url: url,
                                title: method.summary || url,
                                description: method.description || null,
                                parameters: _.map(method.parameters || [], parameter => ({
                                    name: parameter.name,
                                    type: parameter.schema.type,
                                    description: parameter.description,
                                    required: parameter.required,
                                })),
                                responses: _.map(method.responses || [], (response, status) => ({
                                    status,
                                    content: response.content
                                        ? JSON.stringify(sample(response.content['application/json'].schema), null, '\t')
                                        : null,
                                })),
                            };
                        })
                    )
                );
            },
        },
        watch: {
            '$i18n.locale': function () {
                this.fetchApiDocs();
            },
        },
        data() {
            return {
                loading: true,
                globalData: null,
                endpoints: [],
                selected: 0,
            };
        },
        mounted() {
            this.fetchApiDocs();
        },
    };
</script>

<style lang="scss" scoped>
    .docs {
        display: flex;
        flex-direction: column;
        height: 100%;

        > .card {
            display: flex;
            flex-direction: column;

            &:first-of-type {
                flex-shrink: 0;
                max-height: 50%;
                background-color: #131825;
                border-radius: 0.8rem 0.8rem 0 0;

                ::v-deep {
                    > .scroll-container > .scroll-container__content {
                        height: 100%;
                        min-height: unset;
                    }
                }
            }

            &:last-of-type {
                flex-grow: 1;
                height: 50%;
                border-radius: 0 0 0.8rem 0.8rem;

                .card__body > .scroll-container > .scroll-container__content > *:not(:first-child) {
                    margin-top: 1.5rem;
                }
            }
        }
    }

    .docs__list-item {
        margin-bottom: 0.2rem;
        padding: 1.5rem;
        border-radius: 0.6rem;
        background-color: #191f2f;
        color: #989fb6;
        font-family: 'Gotham Book', sans-serif;
        font-size: 1.2rem;
        font-weight: 325;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s, opacity 0.3s;

        &_selected {
            background-color: #3760cb;
            color: #ffffff;
            pointer-events: none;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            opacity: 0.75;
        }
    }

    .docs__url {
        display: inline-block;
        padding: 0.8rem 1rem;
        border: 0.1rem solid #1a2030;
        border-radius: 0.6rem;
        font-family: 'Gotham Medium', sans-serif;
        font-size: 1.2rem;
        font-weight: 350;

        span {
            color: #5988ff;
        }
    }

    .docs__description {
        font-family: 'Gotham Book', sans-serif;
        font-size: 1.2rem;
        font-weight: 325;
        line-height: normal;

        ::v-deep {
            * {
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
            }

            p {
                &:not(:first-child) {
                    margin-top: 1.5rem;
                }

                u {
                    color: #5988ff;
                    text-decoration: underline;
                }
            }
        }
    }

    .docs__response h1 {
        margin-bottom: 1rem;
        color: #5988ff;
        font-family: 'Gotham Book', sans-serif;
        font-size: 1.6rem;
        font-weight: 325;
    }

    ::v-deep {
        .app-layout__content-outer > .scroll-container > .scroll-container__content,
        .app-layout__content-outer > .scroll-container > .scroll-container__content > .app-layout__content {
            height: 100%;
        }
    }

    @media screen and (min-width: 75em) {
        .docs {
            flex-direction: row;

            > .card {
                &:first-of-type {
                    width: 25%;
                    max-height: unset;
                    border-radius: 0.8rem 0 0 0.8rem;
                }

                &:last-of-type {
                    min-width: 0;
                    height: auto;
                    border-radius: 0 0.8rem 0.8rem 0;
                }
            }
        }
    }

    @media screen and (min-width: 120em) {
        .docs {
            > .card {
                &:first-of-type {
                    width: 44rem;
                    border-radius: 1rem 0 0 1rem;
                }

                &:last-of-type {
                    border-radius: 0 1rem 1rem 0;

                    .card__body > .scroll-container > .scroll-container__content > *:not(:first-child) {
                        margin-top: 2.5rem;
                    }
                }
            }
        }

        .docs__list-item {
            margin-bottom: 0.3rem;
            padding: 2rem;
            border-radius: 0.8rem;
            font-size: 1.5rem;
        }

        .docs__url {
            padding: 1.3rem 1.8rem;
            border-radius: 0.8rem;
            font-size: 1.5rem;
        }

        .docs__description {
            font-size: 1.5rem;

            ::v-deep p:not(:first-child) {
                margin-top: 2rem;
            }
        }

        .docs__response h1 {
            margin-bottom: 2rem;
            font-size: 2.2rem;
        }
    }
</style>
